/* You can add global styles to this file, and also import other style files */

/* Provide sufficient contrast against white background */
/* seleccino una row,
y los links tb los pongo blanco */
/* table.dataTable tbody tr.selected td > a {
  color: white !important;
} */

a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.disabled {
  pointer-events: none;
  opacity: 0.7;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.dashboard > .ng-star-inserted {
  width: 11rem;
}

.hideButton {
  display: none;
}

.hideVisivilityButton {
  visibility: hidden;
}

.activeButton {
  /* transform: scale(0.85);
  box-shadow: 3px 2px 25px 1px rgba(0,0,0,0.25); */
  background-color: #dae0e5;
}

/*fix datapicker para que si el input es chico y no hay rangos se vea bien*/
.md-drppicker.double {
  min-width: 31rem !important;
  top: 45px !important;
}

/*fix datapicker para que si el input es chico y no hay rangos se vea bien*/
.md-drppicker.double.show-ranges {
  min-width: 40rem !important;
  top: 80px !important;
}

.md-drppicker .btn {
  padding: 0.25rem 0.5rem !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  border-radius: 0.2rem !important;
  color: #fff !important;
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
  box-shadow: none !important;
  font-weight: 400 !important;
  border: 1px solid transparent !important;
  user-select: none !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out !important;
}

.resaltRow {
  background: #f8eaea !important;
}

/*loading*/

#floatingCirclesG {
  position: relative;
  width: 125px;
  height: 125px;
  margin: auto;
  transform: scale(0.6);
  -o-transform: scale(0.6);
  -ms-transform: scale(0.6);
  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
}

.f_circleG {
  position: absolute;
  background-color: #6439ff;
  height: 22px;
  width: 22px;
  border-radius: 12px;
  -o-border-radius: 12px;
  -ms-border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  animation-name: f_fadeG;
  -o-animation-name: f_fadeG;
  -ms-animation-name: f_fadeG;
  -webkit-animation-name: f_fadeG;
  -moz-animation-name: f_fadeG;
  animation-duration: 1.2s;
  -o-animation-duration: 1.2s;
  -ms-animation-duration: 1.2s;
  -webkit-animation-duration: 1.2s;
  -moz-animation-duration: 1.2s;
  animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-direction: normal;
  -o-animation-direction: normal;
  -ms-animation-direction: normal;
  -webkit-animation-direction: normal;
  -moz-animation-direction: normal;
}

#frotateG_01 {
  left: 0;
  top: 51px;
  animation-delay: 0.45s;
  -o-animation-delay: 0.45s;
  -ms-animation-delay: 0.45s;
  -webkit-animation-delay: 0.45s;
  -moz-animation-delay: 0.45s;
}

#frotateG_02 {
  left: 15px;
  top: 15px;
  animation-delay: 0.6s;
  -o-animation-delay: 0.6s;
  -ms-animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
}

#frotateG_03 {
  left: 51px;
  top: 0;
  animation-delay: 0.75s;
  -o-animation-delay: 0.75s;
  -ms-animation-delay: 0.75s;
  -webkit-animation-delay: 0.75s;
  -moz-animation-delay: 0.75s;
}

#frotateG_04 {
  right: 15px;
  top: 15px;
  animation-delay: 0.9s;
  -o-animation-delay: 0.9s;
  -ms-animation-delay: 0.9s;
  -webkit-animation-delay: 0.9s;
  -moz-animation-delay: 0.9s;
}

#frotateG_05 {
  right: 0;
  top: 51px;
  animation-delay: 1.05s;
  -o-animation-delay: 1.05s;
  -ms-animation-delay: 1.05s;
  -webkit-animation-delay: 1.05s;
  -moz-animation-delay: 1.05s;
}

#frotateG_06 {
  right: 15px;
  bottom: 15px;
  animation-delay: 1.2s;
  -o-animation-delay: 1.2s;
  -ms-animation-delay: 1.2s;
  -webkit-animation-delay: 1.2s;
  -moz-animation-delay: 1.2s;
}

#frotateG_07 {
  left: 51px;
  bottom: 0;
  animation-delay: 1.35s;
  -o-animation-delay: 1.35s;
  -ms-animation-delay: 1.35s;
  -webkit-animation-delay: 1.35s;
  -moz-animation-delay: 1.35s;
}

#frotateG_08 {
  left: 15px;
  bottom: 15px;
  animation-delay: 1.5s;
  -o-animation-delay: 1.5s;
  -ms-animation-delay: 1.5s;
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
}

@keyframes f_fadeG {
  0% {
    background-color: rgb(255, 255, 255);
  }

  100% {
    background-color: #eceefb;
  }
}

@-o-keyframes f_fadeG {
  0% {
    background-color: rgb(255, 255, 255);
  }

  100% {
    background-color: #eceefb;
  }
}

@-ms-keyframes f_fadeG {
  0% {
    background-color: rgb(255, 255, 255);
  }

  100% {
    background-color: #eceefb;
  }
}

@-webkit-keyframes f_fadeG {
  0% {
    background-color: rgb(255, 255, 255);
  }

  100% {
    background-color: #eceefb;
  }
}

@-moz-keyframes f_fadeG {
  0% {
    background-color: rgb(255, 255, 255);
  }

  100% {
    background-color: #eceefb;
  }
}
