/* barra  */

.customnvar {
  background-color: #6439ff;
}

.nav-link {
  color: #eceefb;
}

.main-header {
  border: #eceefb;
}

/*Marca*/

.tenattile {
  color: #000000;
  font-style: italic;
}

.tenattile:hover {
  color: #6439ff;
}

/*menú*/
[class*='sidebar-dark-'] {
  /* color fondo barra lateral */
  background-color: #eceefb !important;
}

[class*='sidebar-dark-'] .sidebar a {
  color: #000000;
}

.sidebar-dark-primary .nav-sidebar > .nav-item {
  > .nav-link {
    &.active,
    &:active {
      color: #ffffff !important;
      background-color: #6439ff;
    }
  }

  /*fondo cunado abro el menu*/
  .nav-treeview {
    background-color: #eceefb !important;
  }
}

.nav-header {
  background-color: #6439ff;
}

.main-header .nav-link:hover {
  color: RGB(18, 253, 243) !important;
}

[class*='sidebar-dark-'] .nav-sidebar > .nav-item.menu-open > .nav-link,
[class*='sidebar-dark-'] .nav-sidebar > .nav-item:hover > .nav-link,
[class*='sidebar-dark-'] .nav-sidebar > .nav-item > .nav-link:focus {
  color: #6439ff;
}

/*cuando paso el mouse por los agrupadores y presiono los agrupadores*/
[class*='sidebar-dark-'] .nav-sidebar > .nav-item.menu-open > .nav-link,
[class*='sidebar-dark-'] .nav-sidebar > .nav-item:hover > .nav-link,
[class*='sidebar-dark-'] .nav-sidebar > .nav-item > .nav-link:focus {
  background-color: #6439ff !important;
  color: #ffffff !important;
}
// &.menu-open > .nav-link,
// &:hover > .nav-link,
// > .nav-link:focus {
//   background-color: #6439ff !important;
//   color: #ffffff !important;
// }

/*cuando tengo el menu abierto*/
[class*='sidebar-dark-'] .nav-treeview {
  > .nav-item {
    > .nav-link {
      /*color de la letra*/
      color: #000000;

      /*color letra hover*/
      &:hover,
      &:focus {
        background-color: #eceefb;
        color: #6439ff;
      }
    }

    /*color fondo y letra la estar presionado*/
    > .nav-link.active {
      &,
      &:hover,
      &:focus {
        background-color: #6439ff;
        color: #ffffff;
      }
    }
  }
}

/* generico */
.btn-bcm,
.buttons-html5,
.btn-primary,
.btn-info {
  background-color: #eceefb !important;
  border-color: #6439ff !important;
  color: #000000 !important;
}

.btn-bcm:hover,
.buttons-html5:hover,
.btn-primary:hover,
.btn-info:hover,
.custombutton:hover {
  background-color: #6439ff !important;
  border-color: #6439ff !important;
  color: #ffffff !important;
}

/* botonera */
.custombutton {
  font-size: 1rem;
  min-width: 10.5rem;
  font-weight: 400;
  background-color: #eceefb !important;
  border: 1px solid #6439ff !important;
  color: #000000 !important;
}

/* .custombutton:hover {
  background-color: #6439ff !important;
  border-color: #6439ff !important;
  color: #ffffff !important;
} */

.btn-secondary:hover {
  background-color: #6439ff !important;
  border-color: #6439ff !important;
  color: #ffffff !important;
}

// /* boton desactivar */
.commoninput:disabled {
  background-color: #9da2a8 !important;
  border-color: #9da2a8 !important;
  color: #ffffff !important;
}

/* textos de titulos */
.text-dark {
  color: #6439ff !important;
}

.breadcrumb li a {
  color: #6439ff !important;
}

.ng-select.ng-select-disabled > .ng-select-container {
  background-color: #9da2a8;
  border-color: #9da2a8;
}

.ng-select.ng-select-disabled .ng-select-container {
  color: #ffffff !important;
}
